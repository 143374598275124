import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import {
  AllInbox,
  Article,
  AttachMoney,
  Ballot,
  Description,
  LocalShipping,
  Mail,
  Schedule,
  Send,
} from '@mui/icons-material';
import { encryptURL } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { NotasContext } from 'contexts/NotasContext';
import { DropsContext } from 'contexts/DropsContext';
import { VendaContext } from 'contexts/VendaContext';
import { ContratosContext } from 'contexts/ContratosContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { useModal, useDialog } from 'components/Modals';
import JustificativaModal from 'components/Modals/JustificativaModal';
import ReferenciaModal from 'components/Modals/ReferenciaModal';
import DeleteModal from 'components/Modals/DeleteModal';
import VendedorModal from './Modals/VendedorModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import CustomTabs from './Tabs';
import moment from 'moment';
import { toastWarning } from 'utils/toast';

const Documento = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const { onChangeTab, tab } = useOutletContext();
  const { id } = useParams();
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { contas } = useContext(VendaContext);
  const { deleteGrid } = useContext(GridContext);
  const { getConsultaNota } = useContext(NotasContext);
  const { PostServicoContrato } = useContext(ContratosContext);
  const { getURLRelatorio, getURLEmissao } = useContext(RelatoriosContext);
  const {
    documento,
    getLoading,
    getDocumento,
    postLoading,
    postDocumento,
    deleteDocumento,
    postAnexo,
    deleteAnexo,
    postDocumentoItemImposto,
    postDocumentoItemCfo,
    postEncerramento,
  } = useContext(DocumentosContext);
  const origem = documento?.DocumentoItems?.find((f) => Boolean(f))
    ?.DocumentoItemOrigems?.[0];
  const disableActions =
    (documento?.especie_id === 9 &&
      origem?.especie_id_origem === 8 &&
      user?.permissoes?.block_altera_pedido_com_orcamento) ||
    (Boolean(documento?.DocumentoHists?.length) &&
      documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))?.Statusdc
        ?.editar === 'NAO');
  const status_id = documento?.DocumentoHists?.filter((f) =>
    Boolean(f?.status_id)
  )?.[0]?.status_id;

  const loadDocumento = () => getDocumento(id);

  useEffect(() => {
    loadDocumento();
    onChangeTab({ tab, key: id });
  }, []);

  const onSubmit = (data, callback = null) =>
    postDocumento({
      data,
      cb: () => {
        loadDocumento();
        if (callback) {
          callback();
        } else {
          closeDialog();
          closeModal();
        }
      },
    });

  const onSubmitDuplicata = (documento_financeiro_id) =>
    getURLRelatorio({
      data: { codigo: 'DUP0000001', documento_financeiro_id },
    });

  const onSubmitBoleto = (financeiros) =>
    getURLRelatorio({
      data: { codigo: 'BOL0000001', financeiros },
    });

  const onSubmitImposto = (data) =>
    postDocumentoItemImposto({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onSubmitCfo = (data) =>
    postDocumentoItemCfo({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onSubmitAnexo = (data) =>
    postAnexo({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onDeleteAnexo = (id) =>
    deleteAnexo({
      id,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onDelete = (params) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params,
            cb: () => {
              loadDocumento();
              closeModal();
            },
          })
        }
      />
    );

  const onDuplicate = () => {
    let data = {
      Documento: {
        cadastro_id: documento?.cadastro_id,
        celocal_id: documento?.celocal_id,
        conferido: 'NAO',
        contribuinte_id: documento?.contribuinte_id,
        dadosadc: documento?.dadosadc,
        dtemissao: documento?.dtemissao,
        especie_id: documento?.especie_id,
        filial_id: documento?.filial_id,
        financeiro_movimento_id: documento?.financeiro_movimento_id,
        gerado: 'NAO',
        indpres: documento?.indpres,
        natureza_operacao_id: documento?.natureza_operacao_id,
        observacao: documento?.observacao,
        relato: documento?.relato,
        users_id: documento?.users_id,
        usofinal: documento?.usofinal,
      },
      Financeiros: documento?.DocumentoFinanceiros?.map((f) => ({
        forma_pagto_id: f?.forma_pagto_id,
        parcela: f?.parcela,
        dtvenc: f?.dtvenc,
        valor: f?.valor,
        observacao: f?.observacao,
      })),
      Transportes: documento?.DocumentoTransportes?.map((t) => ({
        entidade_id: t?.entidade_id,
        tipotransp: t?.tipotransp,
        viatransp: t?.viatransp,
        fretepc: t?.fretepc,
        natureza: t?.natureza,
        locentrega: t?.locentrega,
        volume: t?.volume,
        tara: t?.tara,
        pesoliq: t?.pesoliq,
        pesobrt: t?.pesobrt,
        quantidade: t?.quantidade,
        especie: t?.especie,
        marca: t?.marca,
        numero: t?.numero,
        frota_id: t?.frota_id,
        motorista_id: t?.motorista_id,
      })),
      Itens: documento?.DocumentoItems?.map((i) => {
        const Estruturas = i?.DocumentoItemEstruturas?.map((e) => ({
          componente_id: e?.componente_id,
          operacao: e?.operacao,
          sequencia: e?.sequencia,
          quantidade: e?.quantidade,
          perda: e?.perda,
          volume: e?.volume,
          retornavel: e?.retornavel,
        }));
        const Processos = i?.DocumentoItemProcessos?.map((p) => ({
          operacao: p?.operacao,
          descricao: p?.descricao,
          ccusto_id: p?.ccusto_id,
          tpreparacao: p?.tpreparacao,
          texecucao: p?.texecucao,
          apontamento: p?.apontamento,
          analisa_id: p?.analisa_id,
          aprova_id: p?.aprova_id,
        }));
        const Impostos = i?.DocumentoItemImpostos?.map((imp) => ({
          tributacao_id: imp?.tributacao_id,
          tributo_id: imp?.tributo_id,
          basecalculo: imp?.basecalculo,
          percentual: imp?.percentual,
          valor: imp?.valor,
          ntributado: imp?.ntributado,
          origem_tributaria_id: imp?.origem_tributaria_id,
          situacao_tributaria_id: imp?.situacao_tributaria_id,
          modbc_id: imp?.modbc_id,
          reducao_pc: imp?.reducao_pc,
          reducao_vl: imp?.reducao_vl,
          desoneracao_mot: imp?.desoneracao_mot,
          desoneracao_vlr: imp?.desoneracao_vlr,
          diferido_pc: imp?.diferido_pc,
          mva_pc: imp?.mva_pc,
        }));
        const Variacoes = i?.DocumentoItemVariacaos?.map((vr) => ({
          variacao_id: vr?.variacao_id,
          quantidade: vr?.quantidade,
        }));
        const Margem = Boolean(i?.DocumentoItemMc)
          ? {
              custo: i?.DocumentoItemMc?.custo,
              fretend: i?.DocumentoItemMc?.fretend,
              segurond: i?.DocumentoItemMc?.segurond,
              impfednd: i?.DocumentoItemMc?.impfednd,
              comissaond: i?.DocumentoItemMc?.comissaond,
              internacional: i?.DocumentoItemMc?.internacional,
              saldo: i?.DocumentoItemMc?.saldo,
              saldoc: i?.DocumentoItemMc?.saldoc,
              medio: i?.DocumentoItemMc?.medio,
              medioc: i?.DocumentoItemMc?.medioc,
              custocompra: i?.DocumentoItemMc?.custocompra,
              custocomprac: i?.DocumentoItemMc?.custocomprac,
            }
          : null;
        let data = {
          sequencia: i?.sequencia,
          cfo_id: i?.cfo_id,
          cfiscal_id: i?.cfiscal_id,
          produto_id: i?.produto_id,
          descricao: i?.descricao,
          quantidade: i?.quantidade,
          unidade_medida_id: i?.unidade_medida_id,
          precobase_id: i?.precobase_id,
          preco: i?.preco,
          comissao_id: i?.comissao_id,
          comissao_pc: i?.comissao_pc,
          desconto_vlr: i?.desconto_vlr,
          desconto1_pc: i?.desconto1_pc,
          desconto2_pc: i?.desconto2_pc,
          desconto3_pc: i?.desconto3_pc,
          desconto4_pc: i?.desconto4_pc,
          desconto5_pc: i?.desconto5_pc,
          frete: i?.frete,
          seguro: i?.seguro,
          despac: i?.despac,
          cstfin: i?.cstfin,
          eventuais: i?.eventuais,
          outros: i?.outros,
          peso: i?.peso,
          przent: i?.przent,
          infadc: i?.infadc,
          referencia: i?.referencia,
          calcimp: i?.calcimp,
          reserva: i?.reserva,
          ccusto_id: i?.ccusto_id,
          aplicacao_id: i?.aplicacao_id,
          planoconta_id: i?.planoconta_id,
          frota_id: i?.frota_id,
          observacao: i?.observacao,
          kit_id: i?.kit_id,
        };
        if (Boolean(Estruturas?.length)) {
          data = { ...data, Estruturas };
        }
        if (Boolean(Processos?.length)) {
          data = { ...data, Processos };
        }
        if (Boolean(Impostos?.length)) {
          data = { ...data, Impostos };
        }
        if (Boolean(Variacoes?.length)) {
          data = { ...data, Variacoes };
        }
        if (Boolean(Margem)) {
          data = { ...data, Margem };
        }
        return data;
      }),
    };
    if (Boolean(documento?.DocumentoEntrada)) {
      data = {
        ...data,
        Entrada: {
          serie: documento?.DocumentoEntrada?.serie,
          dtentrada: documento?.DocumentoEntrada?.dtentrada,
          custo: documento?.DocumentoEntrada?.custo,
          ped_fornec: documento?.DocumentoEntrada?.ped_fornec,
        },
      };
    }
    if (Boolean(documento?.DocumentoSaida)) {
      data = {
        ...data,
        Saida: {
          condicao_pagamento_id:
            documento?.DocumentoSaida?.condicao_pagamento_id,
          entidade_endereco_id: documento?.DocumentoSaida?.entidade_endereco_id,
          vendedor_id: documento?.DocumentoSaida?.vendedor_id,
          ped_cliente: documento?.DocumentoSaida?.ped_cliente,
          documento: documento?.DocumentoSaida?.documento,
          dtatend: documento?.DocumentoSaida?.dtatend,
          validade: documento?.DocumentoSaida?.validade,
          desc1: documento?.DocumentoSaida?.desc1,
          desc2: documento?.DocumentoSaida?.desc2,
          desc3: documento?.DocumentoSaida?.desc3,
          desc4: documento?.DocumentoSaida?.desc4,
          desc5: documento?.DocumentoSaida?.desc5,
          forma_envio_id: documento?.DocumentoSaida?.forma_envio_id,
          freteenv: documento?.DocumentoSaida?.freteenv,
          fretecob: documento?.DocumentoSaida?.fretecob,
          freterec: documento?.DocumentoSaida?.freterec,
          divisaopc: documento?.DocumentoSaida?.divisaopc,
          divisaotp: documento?.DocumentoSaida?.divisaotp,
        },
      };
    }
    postDocumento({
      data,
      cb: () => navigate(-1),
    });
  };

  const tabs = [
    {
      value: 0,
      key: 'Documento',
      label: 'Documento',
      icon: <Description />,
    },
    {
      value: 1,
      key: 'Itens',
      label: 'Itens',
      icon: <Ballot />,
    },
    {
      value: 2,
      key: 'Financeiro',
      label: 'Financeiro',
      icon: <AttachMoney />,
    },
    {
      value: 3,
      key: 'Transportes',
      label: 'Transportes',
      icon: <LocalShipping />,
    },
    {
      value: 4,
      key: 'MDFE',
      label: 'MDFE',
      icon: <AllInbox />,
      show: documento && documento?.DocumentoMdfe,
    },
    {
      value: 5,
      key: 'Registros',
      label: 'Registros',
      icon: <Article />,
    },
    {
      value: 6,
      key: 'Forma de Envio',
      label: 'Forma de Envio',
      icon: <Send />,
    },
    {
      value: 7,
      key: 'Status da Transmissão',
      label: 'Status da Transmissão',
      icon: <Schedule />,
      show: documento && documento?.DocumentoNfeServicos?.length,
    },
    {
      value: 8,
      key: 'Carta de Correção',
      label: 'Carta de Correção',
      icon: <Mail />,
      show: documento && documento?.Especie?.modelofiscal_id === 31,
    },
  ];

  const options = [
    {
      name: 'Gerar Pedido',
      icon: 'post_add',
      show:
        documento &&
        documento?.especie_id === 8 &&
        documento?.situacao === 'PENDENTE',
      action: () => navigate(`/app/Pedidos/Gerar/${id}`),
    },
    {
      name: 'Gerar Nota Fiscal',
      icon: 'post_add',
      show:
        documento &&
        (documento?.situacao === 'PENDENTE' ||
          [25, 26]?.includes(documento?.especie_id)) &&
        ![10, 46]?.includes(documento?.especie_id) &&
        status_id !== 13,
      action: () => navigate(`/app/Notas/Gerar/NF/${id}`),
    },
    {
      name: 'Gerar Fatura',
      icon: 'post_add',
      show:
        documento &&
        (documento?.especie_id === 8 || documento?.especie_id === 9) &&
        documento?.situacao === 'PENDENTE' &&
        status_id !== 13,
      action: () => {
        if (Boolean(contas?.cxconta_id)) {
          navigate(`/app/Caixa/Fatura/${id}`);
        } else {
          navigate(`/app/Notas/Gerar/Fatura/${id}`);
        }
      },
    },
    {
      name: 'Gerar Cupom',
      icon: 'post_add',
      show:
        documento &&
        (documento?.especie_id === 8 || documento?.especie_id === 9) &&
        documento?.situacao === 'PENDENTE' &&
        status_id !== 13,
      action: () => {
        if (Boolean(contas?.cxconta_id)) {
          navigate(`/app/Caixa/Cupom/${id}`);
        } else {
          navigate(`/app/Notas/Gerar/Cupom/${id}`);
        }
      },
    },
    {
      name: 'Gerar Garantia',
      icon: 'post_add',
      show:
        documento &&
        documento?.situacao === 'CONCLUIDO' &&
        documento?.Especie?.grupo === 'SAIDA' &&
        documento?.Especie?.natureza === 'FATURA' &&
        Boolean(documento?.cadastro_id),
      action: () => {
        const itens = (
          Boolean(selected?.length) ? selected : documento?.DocumentoItems
        )?.map((m) => m?.id);
        const hash = encryptURL({ id: documento?.id, itens });
        navigate(`/app/Garantias/Gerar/${hash}`);
      },
    },
    {
      name: 'Gerar Requisição de Compra',
      icon: 'post_add',
      show:
        documento &&
        (documento?.especie_id === 8 || documento?.especie_id === 9) &&
        documento?.situacao === 'PENDENTE',
      action: () => {
        const params = Boolean(selected?.length)
          ? `${id}/${JSON.stringify(selected?.map((m) => m?.id))}`
          : `${id}`;
        window.open(
          `https://suprimentos.eprom2.com.br/app/Requisicoes/Gerar/${params}`
        );
      },
    },
    {
      name: 'Gerar Pedido de Compra',
      icon: 'post_add',
      show:
        documento &&
        (documento?.especie_id === 8 || documento?.especie_id === 9) &&
        documento?.situacao === 'PENDENTE' &&
        !!user?.permissoes?.enable_pedido_compra,
      action: () => {
        const params = Boolean(selected?.length)
          ? `${id}/${JSON.stringify(selected?.map((m) => m?.id))}`
          : `${id}`;
        window.open(
          `https://suprimentos.eprom2.com.br/app/Pedidos/Gerar/${params}`
        );
      },
    },
    {
      name:
        'Indicar' +
        (documento?.natureza_operacao_id === 8 ? ' Não' : '') +
        ' Movimentação de Estoque',
      icon: 'info',
      show:
        documento &&
        [7, 8]?.includes(documento?.natureza_operacao_id) &&
        documento?.situacao === 'PENDENTE',
      action: () =>
        onSubmit({
          Documento: {
            id,
            natureza_operacao_id: documento?.natureza_operacao_id === 8 ? 7 : 8,
          },
        }),
    },
    // {
    //   name: 'Gerar Ordem de Serviço',
    //   icon: 'post_add',
    //   show:
    //     documento &&
    //     documento?.especie_id === 8 &&
    //     documento?.situacao === 'PENDENTE',
    //   action: () => {
    //     openModal(
    //       <ReferenciaModal
    //         onSubmit={(dtref) => {
    //           const data = { dtref, documentos: [id] };
    //           PostServicoContrato({
    //             data,
    //             cb: () => {
    //               loadDocumento();
    //               closeModal();
    //             },
    //           });
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      name: 'Cancelar Documento',
      icon: 'cancel',
      show:
        !user?.permissoes?.block_cancela_documentos &&
        Boolean(documento) &&
        (documento?.DocumentoHists?.every((e) => !Boolean(e?.status_id)) ||
          documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))
            ?.Statusdc?.cancelar === 'SIM'),
      action: () =>
        openDialog(
          <JustificativaModal
            documento_id={id}
            callback={() => {
              loadDocumento();
              closeDialog();
            }}
          />,
          'Cancelar Documento'
        ),
    },
    {
      name: 'Aprovar Documento',
      icon: 'rule',
      show:
        documento &&
        documento?.DocumentoItems?.some((d) =>
          d?.DocumentoItemAprovacaos?.some((a) => !Boolean(a?.aprovado))
        ),
      action: () => {
        navigate(`/app/Documento/Aprovacoes`);
      },
    },
    {
      name: 'Emitir Documento',
      icon: 'send',
      action: () =>
        documento && documento?.especie_id === 26
          ? onSubmit({ Emitir: { id, tpamb: 1 } })
          : getURLEmissao({ data: { id }, cb: loadDocumento }),
      show: !documento?.DocumentoItems?.some((a) =>
        a?.DocumentoItemAprovacaos?.some(
          (d) => !Boolean(d?.aprovado) || d?.aprovado === 'NAO'
        )
      ),
    },
    {
      name: 'Carta de Correção',
      icon: 'print',
      action: () =>
        getURLRelatorio({ data: { codigo: 'CCE0000001', documento_id: id } }),
      show:
        documento &&
        Boolean(documento?.DocumentoCces?.length) &&
        documento?.Especie?.modelofiscal_id === 31,
    },
    {
      name: 'Duplicar Documento',
      icon: 'file_copy',
      action: () => onDuplicate(),
    },
    {
      name: 'Pré Visualizar Documento',
      icon: 'preview',
      show:
        documento &&
        documento?.conferido === 'NAO' &&
        documento?.especie_id === 10,
      action: () =>
        getURLEmissao({
          data: { id, previsualizacao: true },
          cb: loadDocumento,
        }),
    },
    {
      name: 'Consultar Situação',
      icon: 'star',
      show:
        documento &&
        (documento?.DocumentoSaida?.Serie?.modelo === 'NFe' ||
          documento?.DocumentoSaida?.Serie?.modelo === 'NFSe'),
      action: () =>
        getConsultaNota({
          id,
          type: documento?.DocumentoSaida?.Serie?.modelo,
          cb: loadDocumento,
        }),
    },
    {
      name: 'Excluir Documento',
      icon: 'delete',
      show:
        !user?.permissoes?.block_exclui_documentos &&
        Boolean(documento) &&
        (documento?.DocumentoHists?.every((e) => !Boolean(e?.status_id)) ||
          documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))
            ?.Statusdc?.excluir === 'SIM'),
      action: () => deleteDocumento({ id, cb: () => navigate(-1) }),
    },
    {
      name: 'Retornar Remessa',
      icon: 'undo',
      show:
        documento &&
        documento?.NaturezaOperacao?.terceiro === 'SIM' &&
        (documento?.especie_id === 5 ||
          documento?.especie_id === 10 ||
          documento?.especie_id === 25 ||
          documento?.especie_id === 34 ||
          documento?.especie_id === 35 ||
          documento?.especie_id === 43),
      action: () => navigate(`/app/Remessas/Retorno/${id}`),
    },
    {
      name: 'Vender Remessa',
      icon: 'monetization_on',
      show:
        documento &&
        documento?.situacao === 'CONCLUIDO' &&
        (documento?.especie_id === 10 ||
          documento?.especie_id === 25 ||
          documento?.especie_id === 43) &&
        (documento?.natureza_operacao_id === 37 ||
          documento?.natureza_operacao_id === 39 ||
          documento?.natureza_operacao_id === 42 ||
          documento?.natureza_operacao_id === 48 ||
          documento?.natureza_operacao_id === 52),
      action: () => {
        const cadastro_id = documento?.cadastro_id;
        const vendedor_id = documento?.DocumentoSaida?.vendedor_id;
        const itens = (
          Boolean(selected?.length) ? selected : documento?.DocumentoItems
        )?.map((s) => s?.id);
        const hash = encryptURL({ itens, cadastro_id, vendedor_id });
        navigate(`/app/Remessas/Venda/${hash}`);
      },
    },
    {
      name: 'Bonificar Remessa',
      icon: 'money_off',
      show:
        documento &&
        documento?.situacao === 'CONCLUIDO' &&
        (documento?.especie_id === 10 ||
          documento?.especie_id === 25 ||
          documento?.especie_id === 43) &&
        (documento?.natureza_operacao_id === 37 ||
          documento?.natureza_operacao_id === 39 ||
          documento?.natureza_operacao_id === 42 ||
          documento?.natureza_operacao_id === 48 ||
          documento?.natureza_operacao_id === 52),
      action: () => {
        const cadastro_id = documento?.cadastro_id;
        const vendedor_id = documento?.DocumentoSaida?.vendedor_id;
        const itens = (
          Boolean(selected?.length) ? selected : documento?.DocumentoItems
        )?.map((s) => s?.id);
        const hash = encryptURL({ itens, cadastro_id, vendedor_id });
        navigate(`/app/Remessas/Bonus/${hash}`);
      },
    },
    {
      name: 'Registrar Documento',
      icon: 'app_registration',
      show:
        documento &&
        documento?.Especie?.registrar === 'SIM' &&
        documento?.situacao === 'PENDENTE' &&
        documento?.conferido === 'NAO',
      action: () =>
        !documento?.DocumentoSaida?.serie_id
          ? toastWarning('Documento não possui série')
          : onSubmit({
              Documento: {
                id,
                conferido: 'SIM',
              },
              Saida: {
                serie_id: documento?.DocumentoSaida?.serie_id,
                dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
              },
              Transmitir: true,
            }),
    },
    {
      name: 'Desregistrar Documento',
      icon: 'app_registration',
      show:
        documento &&
        documento?.Especie?.registrar === 'SIM' &&
        documento?.conferido === 'SIM',
      action: () => onSubmit({ Documento: { id, conferido: 'NAO' } }),
    },
    {
      name: 'Armazenagem',
      icon: 'warehouse',
      show:
        documento &&
        status_id !== 31 &&
        documento?.situacao === 'PENDENTE' &&
        (documento?.especie_id === 10 || documento?.especie_id === 25) &&
        (documento?.natureza_operacao_id === 4 ||
          documento?.natureza_operacao_id === 32),
      action: () =>
        onSubmit({
          Registros: [{ documento_id: documento?.id, status_id: 31 }],
        }),
    },
    {
      name: 'Recebimento',
      icon: 'price_check',
      show:
        documento &&
        documento?.Especie?.natureza === 'FATURA' &&
        Boolean(documento?.DocumentoFinanceiros?.length),
      action: () =>
        window.open(
          `https://financeiro.eprom2.com.br/app/Pendencias/Baixa/Recebimentos/${id}`
        ),
    },
    // {
    //   name: 'Liberar Expedição',
    //   icon: 'local_shipping',
    //   show:
    //     documento &&
    //     ![35, 36, 37, 38, 40, 41, 42]?.includes(status_id) &&
    //     documento?.situacao === 'PENDENTE' &&
    //     (documento?.especie_id === 9 ||
    //       ([10, 25]?.includes(documento?.especie_id) &&
    //         documento?.natureza_operacao_id === 8)) &&
    //     !documento?.DocumentoItems?.some((d) =>
    //       d?.DocumentoItemAprovacaos?.some((a) => !Boolean(a?.aprovado))
    //     ),
    //   action: () =>
    //     onSubmit({ Registros: [{ documento_id: id, status_id: 13 }] }),
    // },
    {
      name: 'Finalizar Contrato',
      icon: 'gavel',
      show:
        documento &&
        status_id !== 12 &&
        documento?.situacao === 'PENDENTE' &&
        documento?.Especie?.natureza === 'CONTRATO',
      action: () =>
        onSubmit({ Registros: [{ documento_id: id, status_id: 12 }] }),
    },
    {
      name: 'Reabrir Contrato',
      icon: 'lock_open',
      show:
        documento &&
        status_id !== 11 &&
        documento?.situacao === 'CONCLUIDO' &&
        documento?.Especie?.registrar === 'NAO' &&
        documento?.Especie?.natureza === 'CONTRATO',

      action: () =>
        onSubmit({ Registros: [{ documento_id: id, status_id: 11 }] }),
    },
    {
      name: 'Devolução',
      icon: 'undo',
      show:
        documento &&
        documento?.situacao === 'CONCLUIDO' &&
        documento?.Especie?.natureza === 'FATURA' &&
        documento?.NaturezaOperacao?.devolucao === 'NAO',
      action: () => navigate(`/app/Documento/Devolucao/${id}`),
    },
    {
      name: 'Concluir Documento',
      icon: 'check',
      show:
        documento &&
        status_id !== 6 &&
        documento?.situacao === 'PENDENTE' &&
        documento?.Especie?.natureza !== 'CONTRATO' &&
        documento?.Especie?.registrar === 'NAO',
      action: () =>
        onSubmit({ Registros: [{ documento_id: id, status_id: 6 }] }),
    },
    {
      name: 'Imprimir Lucratividade',
      icon: 'print',
      show: documento && Boolean(documento?.DocumentoSaida),
      action: () =>
        getURLRelatorio({ data: { codigo: 'LUC0000001', documento: id } }),
    },
    {
      name: 'Imprimir Expedição',
      icon: 'print',
      show:
        documento &&
        documento?.situacao === 'PENDENTE' &&
        documento?.especie_id === 9,
      action: () =>
        getURLRelatorio({ data: { codigo: 'EXP0000001', doc: id } }),
    },
    {
      name: 'Liberar Produção',
      icon: 'all_inbox',
      show:
        documento &&
        status_id !== 33 &&
        documento?.especie_id === 9 &&
        documento?.situacao === 'PENDENTE',
      action: () =>
        onSubmit({
          Registros: [{ documento_id: documento?.id, status_id: 33 }],
        }),
    },
    {
      name: 'Encomenda',
      icon: 'local_shipping',
      show:
        documento &&
        documento?.situacao === 'PENDENTE' &&
        (documento?.especie_id === 8 || documento?.especie_id === 9),
      action: () =>
        onSubmit({ Registros: [{ documento_id: id, status_id: 43 }] }),
    },
    {
      name: 'Encerrar Transporte',
      icon: 'no_crash',
      show: documento && documento?.DocumentoMdfe?.sts === 100,
      action: () => postEncerramento({ data: { id }, cb: loadDocumento }),
    },
    {
      name: 'Alterar Vendedor',
      icon: 'edit',
      show:
        documento &&
        !user?.permissoes?.altera_vendedor &&
        documento?.situacao === 'CONCLUIDO' &&
        documento?.Especie?.natureza === 'FATURA',

      action: () =>
        openModal(<VendedorModal documento={documento} onSubmit={onSubmit} />),
    },
  ];

  return (
    <Container>
      <Header
        titulo={`Documento: ${documento?.documento || ''}`}
        subtitulo={`Status: ${documento?.situacao || ''}`}
        outros={options}
      />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, tab) => onChangeTab({ tab, key: id })}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        user={user}
        drops={drops}
        documento={documento}
        contas={contas}
        getLoading={getLoading}
        disableActions={disableActions}
        loading={postLoading}
        onDelete={onDelete}
        onSubmit={onSubmit}
        onSubmitBoleto={onSubmitBoleto}
        onSubmitDuplicata={onSubmitDuplicata}
        onSubmitImposto={onSubmitImposto}
        onSubmitCfo={onSubmitCfo}
        onSubmitAnexo={onSubmitAnexo}
        onDeleteAnexo={onDeleteAnexo}
        selected={selected}
        setSelected={setSelected}
      />
    </Container>
  );
};

export default Documento;
