import React, {
  forwardRef,
  useRef,
  useEffect,
  createContext,
  useContext,
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  IconButton,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { Refresh, SavedSearch, SearchOff } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { VariableSizeList } from 'react-window';
import { DropsContext } from 'contexts/DropsContext';
import Input from 'components/Input';
import styles from './styles';

const renderRow = ({ data, index, style }) => {
  const dataSet = data[index];
  return (
    <Box
      {...dataSet[0]}
      key={dataSet[1]?.value}
      sx={styles?.optionContainer(style)}
    >
      <Typography sx={styles?.optionLabel} variant="body1" noWrap>
        {dataSet[1]?.label}
      </Typography>
      {Object?.keys(dataSet[1])
        ?.filter(
          (f) => f?.substring(0, 1) === f?.substring(0, 1)?.toUpperCase()
        )
        ?.map((o, i) => (
          <Typography
            key={i?.toString()}
            sx={styles?.optionSubLabel}
            variant="body2"
          >
            {o}: {dataSet[1][o]}
          </Typography>
        ))}
    </Box>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = forwardRef(({ children, ...other }, ref) => {
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });
  const itemCount = itemData.length;

  const count = Math.max.apply(
    Math,
    itemData?.map(
      (d) =>
        Object?.keys(d[1])?.filter(
          (f) => f?.substring(0, 1) === f?.substring(0, 1)?.toUpperCase()
        )?.length
    )
  );

  const itemSize = 36 + 20 * count;
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(() => itemSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          ref={gridRef}
          outerElementType={OuterElementType}
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const VirtualDrop = ({
  control,
  name,
  options = [],
  label,
  onValueChange,
  refresh,
  busca_refinada,
  ...props
}) => {
  const { getDrops } = useContext(DropsContext);
  const [refinada, setRefinada] = useState(false);

  const onRefresh = () => getDrops([refresh]);

  const onRefinada = () => setRefinada((prev) => !prev);

  const getFilterOptions = (options, state) => {
    const getRelevantKeys = (option) =>
      Object.keys(option).filter(
        (key) =>
          key.charAt(0) === key.charAt(0).toUpperCase() || key === 'label'
      );

    const filterOptions = createFilterOptions({
      stringify: (option) => {
        const relevantKeys = getRelevantKeys(option);
        return relevantKeys.map((key) => option[key]).join('');
      },
    });

    return filterOptions(options, state).filter((option) => {
      if (refinada && state?.inputValue) {
        const relevantKeys = getRelevantKeys(option);
        return relevantKeys.some((key) => option[key] == state?.inputValue);
      }
      return true;
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box sx={styles?.container}>
          <Autocomplete
            {...props}
            clearOnEscape
            disableListWrap
            sx={{ ...styles?.drop, ...props?.sx }}
            options={options || []}
            value={field?.value}
            filterOptions={getFilterOptions}
            ListboxComponent={ListboxComponent}
            renderInput={(params) => (
              <Input
                {...params}
                {...field}
                {...props}
                label={label}
                onChange={() => {}}
              />
            )}
            renderOption={(props, option, state) => [
              props,
              option,
              state.index,
            ]}
            renderGroup={(params) => params}
            onChange={(_, data) => {
              if (!!props?.multiple) {
                field?.onChange(data?.map((d) => d.value || d));
                if (!!onValueChange) {
                  onValueChange(data?.map((d) => d.value || d));
                }
              } else {
                field?.onChange(data?.value || null);
                if (!!onValueChange) {
                  onValueChange(data || null);
                }
              }
            }}
            getOptionLabel={(option) => {
              if (typeof option !== 'object') {
                const ret = options?.find((c) => c.value === option);
                return option?.label || ret?.label || '';
              }
              return option.label || '';
            }}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
          {Boolean(refresh) && (
            <Tooltip title="Atualizar" placement="top">
              <IconButton size="small" onClick={onRefresh}>
                <Refresh fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {Boolean(busca_refinada) && (
            <Tooltip
              title={
                (busca_refinada && refinada ? 'Desativar' : 'Ativar') +
                ' Busca Refinada'
              }
              placement="top"
            >
              <IconButton size="small" onClick={onRefinada}>
                {busca_refinada && refinada ? (
                  <SearchOff fontSize="small" />
                ) : (
                  <SavedSearch fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    />
  );
};

export default VirtualDrop;
